import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import { LanguageContext } from "../languages/LanguageContext";

export default function CustomModal(props) {
  const { i18n } = useContext(LanguageContext);
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    setOpen(false);
    if (props.typeText === "error") {
      props?.dispatch({ type: "set_reservation_error", payload: "" });
      props?.dispatch({ type: "set_trips_error", payload: "" });
      props?.dispatch({ type: "set_google_map_error", payload: "" });
    }
  };

  const style = {
    position: "absolute",
    top: props.typeText === "terms" ? "0%" : "50%",
    left: props.typeText === "terms" ? "0%" : "50%",
    transform:
      props.typeText === "terms"
        ? "translate(10%, 0%)"
        : "translate(-50%, -50%)",
    width: props.typeText === "terms" ? "80%" : 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={{ overflow: "scroll" }}
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography variant="h6" component="h2">
            {props.title}
          </Typography>
          {props.typeText === "terms" ? (
            <>{props.text()}</>
          ) : (
            <Typography
              color={(t) =>
                props.typeText === "error" ? t.palette.error.main : ""
              }
              sx={{ mt: 2 }}
            >
              {props.text()}
            </Typography>
          )}
          <Button onClick={props.closeModal ? props.closeModal : handleClose}>
            {i18n.appData.close}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

import { Link, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";

import { LanguageContext } from "../languages/LanguageContext";

const SuccessReservation = () => {
  const { i18n } = useContext(LanguageContext);
  return (
    <React.Fragment>
      <Paper square elevation={0} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          {window.location.href.includes("success-payment") ? i18n.payment.successTitle : i18n.payment.saveTitle}
        </Typography>
        <Typography variant="subtitle1">
          {window.location.href.includes("success-payment") ? i18n.payment.successText: i18n.payment.saveText}{" "}
          <Link href={`mailto:${i18n.swanData.email}`}>
            {i18n.swanData.email}
          </Link>
        </Typography>
        <Link href="/" sx={{ mt: 1, mr: 1 }}>
          {i18n.appData.returnHome}
        </Link>
      </Paper>
    </React.Fragment>
  );
};

export default SuccessReservation;

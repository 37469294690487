import React, { useState, useContext } from "react";
import dayjs from "dayjs";

import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Collapse,
  Link,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import RouteMap from "../RouteMap/RouteMap";
import CustomModal from "../CustomModal";
import TermsAndCondition from "../TermsAndCondition";
import { LanguageContext } from "../../languages/LanguageContext";
import { handleBack, onMakeReservation } from "../AppContainer/utilities";

const Review = (props) => {
  const { i18n } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);
  const [openDebugDtails, setOpenDebugDetails] = useState(false);
  const selectedQuote = props.state?.tripQuotes.quotes.filter(
    (quote) => quote.vehicle_type.id === props.state.selectedVehicle?.id
  )[0];

  const generateStopName = (stop) => {
    if (stop.stop_type === "PICK UP") return i18n.trips.pickUp;
    if (stop.stop_type === "DROP OFF") return i18n.trips.dropOff;
    return `${i18n.trips.stop} ${stop.order - 1}`;
  };

  const getRoundRouteDuration = () => {
    let stopsDuration = props.state.tripQuotes.trip.stops.reduce(
      (acc, current) => acc + current.wait_time,
      0
    );

    let roundRouteDuration = Math.ceil(props.state.routeDuration / 60);
    return stopsDuration + roundRouteDuration;
  };

  const isFuelSurchargePositive = () => {
    return Number(selectedQuote.fuel_surcharge.replace(/[^0-9.,-]+/g, "").replace(/,/g, '.')) > 0
  }

  return props.state.reservationLoad ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: "45%",
        mb: "45%",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    selectedQuote && (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          {i18n.appData.review}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {i18n.appData.tripDetails}
            </Typography>
            <List disablePadding>
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary={i18n.review.tripDate} />
                <Typography variant="body2">
                  {dayjs(props.state.tripQuotes.trip.date_time_pick_up).format(
                    "MM/DD/YYYY"
                  )}
                </Typography>
              </ListItem>
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText
                  primary={i18n.review.tripTime}
                  secondary={props.state.tripQuotes.trip.timezone}
                />
                <Typography variant="body2">
                  {dayjs(props.state.tripQuotes.trip.date_time_pick_up).format(
                    "hh:mm A"
                  )}
                </Typography>
              </ListItem>
              <ListItem sx={{ py: 1, px: 0, display: "block" }}>
                <ListItemText primary={i18n.review.route} />
                {props.state.tripQuotes.trip.stops.map((stop) => (
                  <div key={stop.order}>
                    <ListItemText secondary={generateStopName(stop)} />
                    <Typography variant="body2">
                      {stop?.name || stop.address}
                    </Typography>
                  </div>
                ))}
              </ListItem>
              {props.state.tripQuotes.trip?.stops[0]?.flight_number && (
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText primary={i18n.review.flightNumber} />
                  <Typography variant="body2">
                    {props.state.tripQuotes.trip?.stops[0]?.flight_number}
                  </Typography>
                </ListItem>
              )}
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary={i18n.review.noPass} />
                <Typography variant="body2">
                  {props.state.tripQuotes.trip.passenger_count}
                </Typography>
              </ListItem>
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary={i18n.review.vehicleSelected} />
                <Typography variant="body2">
                  {props.state.selectedVehicle.name}
                </Typography>
              </ListItem>
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText
                  primary={
                    <Typography type="body2" sx={{ fontWeight: "bold" }}>
                      {i18n.review.vehicleCount}
                    </Typography>
                  }
                />
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {selectedQuote.vehicle_count}
                </Typography>
              </ListItem>
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary={i18n.review.duration} />
                <Typography variant="body2">
                  {props.state.tripQuotes.trip.mode === "TRANSPORT" &&
                    Math.ceil(props.state.routeDuration / 60)}
                  {props.state.tripQuotes.trip.mode === "HOURLY" &&
                    props.state.tripQuotes.trip.duration}
                  {props.state.tripQuotes.trip.mode === "ROUND" &&
                    getRoundRouteDuration()}
                  {i18n.review.min}
                </Typography>
              </ListItem>
              {process.env.REACT_APP_TEST_MODE && (
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemButton
                    onClick={() => {
                      setOpenDebugDetails(!openDebugDtails);
                    }}
                  >
                    <ListItemIcon>{">"}</ListItemIcon>
                    <ListItemText primary={i18n.review.debugDetails} />
                  </ListItemButton>
                </ListItem>
              )}
              {process.env.REACT_APP_TEST_MODE && (
                <Collapse in={openDebugDtails}>
                  <List sx={{ ml: 2 }}>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.correctedTime} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.corrected_time_in_minutes}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.fare} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.fare}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.mileageInflation} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.mileage_inflation}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.routeMiles} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.route_miles}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.routeMinutes} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.route_minutes}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.timeMinutes} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.time_in_minutes}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemText primary={i18n.review.garageName} />
                      <Typography variant="body2">
                        {selectedQuote.debug_details.garage_name}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              )}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} height="400px">
            {props.isLoaded ? (
              <RouteMap
                directionsResponse={props.state.directionsResponse}
                dropOffMarker={{
                  lat: props.state.dropOff?.geometry?.location.lat(),
                  lng: props.state.dropOff?.geometry?.location.lng(),
                }}
                state={props.state}
                dispatch={props.dispatch}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "45%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Grid>
          <Grid item container direction="column" md={6} xs={12} sm={6}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {i18n.payment.paymentDetails}
            </Typography>
            <Grid container>
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography gutterBottom>{i18n.review.fare}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{selectedQuote.fare + `${props.state.isStaff ? ' (' + props.state.selectedVehicle.name + ')' : ''}`}</Typography>
                </Grid>
              </React.Fragment>
            </Grid>
            {process.env.REACT_APP_TEST_MODE && props.state.isStaff &&
              <Grid container>
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{i18n.review.units}:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{selectedQuote.debug_details.units + ` (${selectedQuote.fare}/${selectedQuote.debug_details.units})`}</Typography>
                  </Grid>
                </React.Fragment>
              </Grid>}
            <Grid container>
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {i18n.review.gratuity} {props.state.pricingData?.gratuity && `(${props.state.pricingData.gratuity} %)`}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{selectedQuote.gratuity}</Typography>
                </Grid>
              </React.Fragment>
            </Grid>
            {isFuelSurchargePositive() &&
              <Grid container>
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography gutterBottom>
                      {i18n.review.fuelSurcharge} {props.state.pricingData?.fuel_surcharge && `(${props.state.pricingData.fuel_surcharge} %)`}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>
                      {selectedQuote.fuel_surcharge}
                    </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
            }
            <Grid container>
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {i18n.review.totalPerVehicle}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    sx={{ fontWeight: 700 }}
                  >
                    {selectedQuote.total_per_vehicle}
                  </Typography>
                </Grid>
              </React.Fragment>
            </Grid>
            <Grid container>
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography gutterBottom>{i18n.review.total}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    sx={{ fontWeight: 700 }}
                  >
                    {selectedQuote.total}
                  </Typography>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
          {!props.state.isStaff &&
            <Grid item container direction="column" md={6} xs={12} sm={6}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                {i18n.contact.contactDetails}
              </Typography>
              <Grid container>
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{i18n.contact.name}:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>
                      {`${props.state.contactData.first_name} ${props.state.contactData.last_name}`}
                    </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
              <Grid container>
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{i18n.contact.email}:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>
                      {props.state.contactData.email}
                    </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
              <Grid container>
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{i18n.contact.phone}:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>
                      {props.state.contactData.phone}
                    </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
          }
          {!props.state.isStaff &&
            <Grid item xs={12}>
              {" "}
              <Typography>
                {i18n.terms.acceptText}{" "}
                <Link
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {i18n.terms.termAndCond}
                </Link>
                .
              </Typography>{" "}
            </Grid>}
          {showModal && (
            <CustomModal
              open={showModal}
              closeModal={() => {
                setShowModal(false);
              }}
              typeText={"terms"}
              title={i18n.terms.termAndCond}
              text={() => <TermsAndCondition />}
            />
          )}
          {props.state.reservationError !== "" && (
            <CustomModal
              open={true}
              dispatch={props.dispatch}
              title="Error"
              text={() => <>{props.state.reservationError}</>}
              typeText={"error"}
            />
          )}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={() => { handleBack(props.dispatch, props.state, props.scrollToBookingSection) }} sx={{ mt: 3, ml: 1 }}>
                {i18n.appData.back}
              </Button>
              {!props.state.isStaff && <Button
                variant="contained"
                type="submit"
                sx={{ mt: 3, ml: 1 }}
                onClick={() => { onMakeReservation(props.dispatch, props.state, i18n, props.scrollToBookingSection) }}
              >
                {i18n.appData.makeAndPay}
              </Button>}
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  );
};

export default Review;

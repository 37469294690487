import React, {useContext} from 'react';

import language from './languages';

export let LanguageType = 'en' | 'fr';


export const LanguageContext = React.createContext({
  i18n: language.en,
  language: 'en',
  setLanguage: () => {
    throw new Error('setLanguage() not implemented');
  },
});

export const useLanguageContext = () => {
  return useContext(LanguageContext);
};

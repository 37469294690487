import React from "react";

import { Box, Divider, Typography } from "@mui/material";

const CustomCardTravelType = (props) => {
  return (
    <Box
      component="span"
      sx={{
        background: `url(${props.src})`,
        borderRadius: "25px 25px",
        height: "339px",
        width: "303px",
        margin: 3,
        padding: 2,
      }}
    >
      <Typography
        variant="h4"
        textTransform={"uppercase"}
        paddingTop={22}
        // paddingTop={15}
        sx={{
          color: props.color,
          whiteSpace: "pre-line",
          fontWeight: "bold",
        }}
      >
        {props.titleText}
      </Typography>
      <Typography variant="body" color={props.color} fontWeight="bold">
        {props.description}
      </Typography>
      {/* <Divider
        sx={{
          bgcolor: props.color,
          borderBottomWidth: 2,
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <Typography variant="body" color={props.color} fontWeight="bold">
        {props.learnMore}
      </Typography> */}
    </Box>
  );
};

export default CustomCardTravelType;

import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { LanguageContext } from '../../languages/LanguageContext';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Header = () => {
    const { i18n } = useContext(LanguageContext)
    const navigate = useNavigate()

    const logoutStaff = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
        navigate('/')
    }

    return (
        <AppBar
            position="absolute"
            color="default"
            elevation={0}
            sx={{
                position: 'relative',
                borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
        >
            <Toolbar >
                <Link href="/" underline="none" color="inherit">
                    <Typography variant="h6" color="inherit" noWrap>
                        {i18n.swanData.title}
                    </Typography>
                </Link>
                <Button
                    onClick={logoutStaff}
                    sx={{ mr: 0, ml: 'auto' }}
                    color="inherit"
                >
                    {i18n.staff.logout}
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default Header
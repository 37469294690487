import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import MainPage from "./pages/MainPage";
import LoginPage from './pages/LoginPage';
import StaffMainPage from "./pages/StaffMainPage";
import { isTokenExpired } from "./api/api_auth";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#EF45C3",
        rgbMain: "239, 69, 195",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      mode: "dark",
    },
    typography: {
      fontFamily: ["Tinos"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<MainPage />} />
          <Route path="/staff-quote" element={
            localStorage.getItem("token") && !isTokenExpired(localStorage.getItem("token")) ?
              <StaffMainPage /> : <Navigate to="/login" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export const validateTripsForm = (tripData) => {
  if (tripData.mode === "TRANSPORT") {
    return (
      validatePassengers(tripData.passenger_count) &&
      validatePickUp(tripData.stops[0]) &&
      validateDropOff(tripData.stops.slice(-1)[0]) &&
      validateStops(tripData.stops)
    );
  } else if (tripData.mode === "ROUND") {
    return (
      validatePassengers(tripData.passenger_count) &&
      validatePickUp(tripData.stops[0]) &&
      validateStops(tripData.stops) &&
      (tripData.return_to_pick_up
        ? true
        : validateDropOff(tripData.stops.slice(-1)[0]))
    );
  } else {
    return (
      validatePassengers(tripData.passenger_count) &&
      // && validateHours(tripData.duration)
      validatePickUp(tripData.stops[0]) &&
      (tripData.return_to_pick_up
        ? true
        : validateDropOff(tripData.stops.slice(-1)[0]))
    );
  }
};

export const validateDateTime = (dateTime, pickUp) => {
  if (pickUp) {
    let dateTimeNowUtc = dayjs().utcOffset(pickUp["utc_offset_minutes"]);
    let dateTimeUtc = dayjs(dateTime).utcOffset(
      pickUp["utc_offset_minutes"],
      true
    );
    return dateTimeNowUtc.isBefore(dateTimeUtc, "minute");
  }
  return true;
};

export const validatePassengers = (passengers) =>
  passengers > 0 && Number.isInteger(passengers);
export const validateHours = (hours) => /^\d+(\.\d{0,2})?$/g.test(hours);
export const validatePickUp = (pickUp) => pickUp?.address !== "";
export const validateDropOff = (dropOff) => dropOff?.address !== "";
export const validateStops = (stops) =>
  stops.lenght === 0
    ? true
    : stops.reduce((acc, current) => acc && validateStop(current), true);
export const validateStop = (stop) => stop?.address !== "";

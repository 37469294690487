import React from "react";
import { AppBar, Container } from "@mui/material";
import Header from "../components/Header/Header";
import AppContainer from "../components/AppContainer/AppContainer";

const StaffMainPage = () => {


    return (
        <>
            <Header />
            <AppContainer staff/>
        </>
    );
};

export default StaffMainPage
import React, { useContext } from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import { LanguageContext } from "../../languages/LanguageContext";
import SuccessReservation from "../SuccessReservation";
import { Box } from "@mui/material";
import { getStepContent } from "./utilities";

const DesktopContainer = (props) => {
  const { i18n } = useContext(LanguageContext);
  return (
    <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          {i18n.appData.bookTrip.toUpperCase()} 
          <Box component="span" color={(t)=>t.palette.primary.main}>{i18n.appData.clicks.toUpperCase()}</Box>
        </Typography>
        <Stepper activeStep={props.state.activeStep} sx={{ pt: 3, pb: 5 }}>
          {props.steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {props.state.activeStep === props.steps.length ? (
          <SuccessReservation />
        ) : (
          <React.Fragment>
            {getStepContent({ ...props, step: props.state.activeStep })}
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
};

export default DesktopContainer;

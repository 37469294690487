export default class Trip {
    constructor( stops, date_time, passenger_count, mode, hours, return_to_pick_up,customer) {
      this.stops = stops || []
      this.date_time_pick_up = date_time  || ''
      this.passenger_count = passenger_count || 1
      this.mode = mode || ''
      this.duration = hours || 0
      this.return_to_pick_up = return_to_pick_up
      this.customer = customer || null
    }
  }
export default class Stop {
    constructor(order, address, location_type, stop_type, flight_number, lat, lng, wait_time, name) {
      this.order = order || ''
      this.address = address  || ''
      this.location_type = location_type || ''
      this.stop_type = stop_type || ''
      this.flight_number = flight_number || ''
      this.lat = lat
      this.lng = lng
      this.wait_time = wait_time || 0
      this.name = name
    }
  }
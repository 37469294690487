import API from "../api/api";


export class ReservationsService {

    async postReservation( reservationData ) { 
        const data = await API.post(`reservations/`, reservationData) 
        return data
    }

}

export const reservationsService = new ReservationsService()

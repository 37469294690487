import { Avatar, Card, CardActionArea, CardActions, CardContent, CardHeader, Collapse, IconButton, Rating, Typography } from '@mui/material'
import React from 'react'

const CustomCardReview = (props) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };



    return (
        <Card sx={{ maxWidth: 345, borderRadius: '15px', backgroundColor: 'white' }}>
            <CardHeader
                color={'black'}
                avatar={
                    <Avatar sx={{ bgcolor: (t) => t.palette.primary.main, color: 'white' }}>
                        R
                    </Avatar>
                }
                title={<Typography sx={{ color: 'black', fontWeight: '700' }}>{props.titleText}</Typography>}
                subheader={<Typography color={'black'}>{props.date}</Typography>}
            />
            <CardActions sx={{ pl: 2 }}>
                <Rating name="read-only" value={props.stars} readOnly />
            </CardActions>
            <CardContent onClick={handleExpandClick}>
                <Typography variant="body2" color={'black'}
                    sx={!expanded && {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical"
                    }} >
                    {props.text}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default CustomCardReview
import React from "react";

import { Box, Typography } from "@mui/material";

const CustomRouteCard = (props) => {
  return (
    <Box
      sx={{
        border: "1px solid white",
        margin: 1,
        padding: 1,
        borderRadius: "10px 10px",
        display: "grid",
        height: {
          lg: "7rem",
          md: "9rem",
          sm: "9.5rem",
          xs: "9.8rem",
        },
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {props.route}
      </Typography>
      <Typography
        variant="body"
        sx={(t) => ({
          color: `${t.palette.primary.main}`,
          marginBottom: "0px",
          marginTop: "auto",
        })}
      >
        {props.price}
      </Typography>
    </Box>
  );
};

export default CustomRouteCard;

import React, { useContext } from "react";

import { Grid, Link, Typography } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import {
  BBBlogo,
  GCLAlogo,
  GoogleLogo,
  NLALogo,
  YelpLogo,
} from "../../resources/images";
import CustomSponsorImg from "../CustomSponsorImg";

const ContactSponsors = () => {
  const { i18n } = useContext(LanguageContext);

  return (
    <>
      <Grid
        container
        alignSelf="center"
        marginTop={10}
        justifyContent={"space-between"}
        sx={{
          paddingTop: 2,
          paddingBottom: 5,
          paddingLeft: 3,
          paddingRight: 3,
          background: "white",
          height: {
            lg: "8rem",
            md: "8rem",
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <CustomSponsorImg src={GoogleLogo.src} />
        <CustomSponsorImg src={NLALogo.src} />
        <CustomSponsorImg src={BBBlogo.src} />
        <CustomSponsorImg src={GCLAlogo.src} />
        <CustomSponsorImg src={YelpLogo.src} />
      </Grid>
      <Grid
        container
        padding={1}
        justifyContent={"space-between"}
        marginBottom={10}
        sx={(t) => ({
          background: `${t.palette.primary.main}`,
          height: {
            lg: "8rem",
            md: "8rem",
            sm: "100%",
            xs: "100%",
          },
        })}
      >
        <Grid
          item
          sx={{
            alignSelf: {
              lg: "start",
              md: "start",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                lg: "24px",
                md: "24px",
                sm: "24px",
                xs: "6.3vw",
              },
            }}
            variant="h5"
          >
            {i18n.swanData.address1}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                lg: "24px",
                md: "24px",
                sm: "24px",
                xs: "6.3vw",
              },
            }}
            variant="h5"
          >
            {i18n.swanData.address2}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            paddingTop: {
              lg: 10,
              md: 10,
              sm: 0,
              xs: 0,
            },
            alignSelf: {
              lg: "end",
              md: "end",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                lg: "24px",
                md: "24px",
                sm: "24px",
                xs: "6.3vw",
              },
            }}
            variant="h5"
          >
            {i18n.swanData.tcp}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            alignSelf: {
              lg: "start",
              md: "start",
              sm: "none",
              xs: "none",
            },
            textAlign: {
              lg: "right",
              md: "right",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <Link
            variant="h5"
            display="block"
            sx={{
              color: "white",
              textDecoration: "underline",
              fontSize: {
                lg: "24px",
                md: "24px",
                sm: "24px",
                xs: "6.3vw",
              },
            }}
            href={`tel:${i18n.swanData.phoneCall}`}
          >
            {i18n.swanData.phoneShow}
          </Link>
          <Link
            variant="h5"
            sx={{
              color: "white",
              textDecoration: "underline",
              fontSize: {
                lg: "24px",
                md: "24px",
                sm: "24px",
                xs: "6.3vw",
              },
            }}
            href={`mailto:${i18n.swanData.email}`}
          >
            {i18n.swanData.email}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactSponsors;

const swanEmail = "reservations@swandiego.com";
const swanPhoneShow = "+1(619)658-0180";
const swanPhoneCall = "+16196580180";
const swanStreet = "3430 Hancock Street";
const swanCity = "San Diego, CA, 92110";
const tcp = "TCP 38726-A";

const languages = {
  en: {
    swanData: {
      email: swanEmail,
      title: "SWAN Limo",
      address1: `${swanStreet}`,
      address2: `${swanCity}`,
      phoneShow: `${swanPhoneShow}`,
      phoneCall: `${swanPhoneCall}`,
      tcp: `${tcp}`,
    },
    landingPage: {
      mainTitle: "Swan Executive Services",
      textTitle: "Transporting The World",
      bookNow: "Book now",
      callUs: "Call us",
      cookies:
        "This page use cookies to ensure you get the best experience on our website.",
      privacyPolicy: "Privacy Policy",
      gotIt: "Got it!",
      learnMore: "Learn More",
      businessCardTitle: "Business\nTravel",
      businessCardDescription: "Corporate Travel Solutions",
      familyCardTitle: "Family\nTravel",
      familyCardDescription: "All Sizes Family Solutions",
      leisureCardTitle: "Leisure\nTravel",
      leisureCardDescription: "Worldwide Travel",
      car1: "Executive Sedan",
      car1description: "BMW 5-Series",
      car2: "Executive SUV",
      car2description: "GMC Yukon XL",
      car3: "Executive Sprinter",
      car3description: "No desc",
      car4: "Executive Minibus",
      car4description: "No desc",
      car5: "Executive Bus",
      car5description: "No desc",
      car6: "Motorcoach",
      car6description: "No desc",
      topRoutes1: "Top",
      topRoutes2: "Routes",
      reviewTitle1: "Reviews From Our",
      reviewTitle2: "Customers Around The World",
      popularLocationsTitle1: "Popular",
      popularLocationsTitle2: "Locations",
      testimonialsTitle: "Testimonials",
      testimonialsText: "We booked Swan Limo last night for a date night of sorts, and was completely pleased with the level of service we received.From the get go, Jordan was very polite and professional.This level of service continued as both drivers - Rafael (Pick Up) and Pedro(Return) - were amazing in both conversation as well as  Professionalism.We will definitely be using Swan moving forward and highly recommend them to anyone looking for something better than using a service like Uber Black.",
      routes: {
        route1: "SAN Airport > Little Italy",
        price1: "Starting at $91.00",
        route2: "SAN Airport > Legoland",
        price2: "Starting at $149.00",
        route3: "SAN Airport > CBX",
        price3: "Starting at $135.00",
        route4: "SAN Airport > Safari Park",
        price4: "Starting at $153.00",
        route5: "SAN Airport > La Costa Resort",
        price5: "Starting at $136.00",
        route6: "SAN Airport > Disneyland",
        price6: "Starting at $299.00",
        route7: "SAN Airport > SDSU",
        price7: "Starting at $91.00",
        route8: "SAN Airport > La Jolla",
        price8: "Starting at $100.00",
      },
    },
    appData: {
      returnHome: "Return to Home Page",
      bookTrip: "Book a ride in a few ",
      clicks: "clicks",
      next: "Next",
      back: "Back",
      close: "Close",
      makeAndPay: "Make reservation and pay",
      tripInformation: "Trip information",
      yourTrip: "Your trip",
      hourly: "Hourly",
      transfer: "One Way",
      roundTrip: "Round Trip",
      select: "Select",
      review: "Review",
      tripDetails: "Trip Details",
    },
    steps: {
      trip: "Trips Details",
      vehicle: "Vehicle Information",
      review: "Review",
    },
    errors: {
      serverError: "An error occurred. You can try reloading the page.",
      pickUpRequired: "Pick up is required",
      mustDigits: "Must be only digits",
      hoursRequired: "Hours is required",
      dropOffRequired: "Drop off is required",
      firstNameRequired: "First name is required",
      lastNameRequired: "Last name is required",
      emailValid: "Email must be in valid format",
      emailRequired: "Email is required",
      phoneValid: "The phone number is not valid",
      phoneRequired: "A phone number is required",
      stopRequired: "Stop is required",
      stopTimeRequired: "Stop time is required",
      dateTime: "Date & Time cannot be in past",
      passengersError: "Passengers number not valid",
      vehicleValidation: "At least one vehicle needs to be selected",
      maxFlightNumber: "Flight number must be less than 10 characters",
      passwordRequired: "Password is required",
      checkYou: "Check your credentials or contact your admin.",
    },
    staff: {
      login: "Sign in",
      logout: "Logout",
      staffLogin: "Swan Limo Login"
    },
    trips: {
      time: "Time",
      passengers: "Passengers",
      passengersPlaceholder: "Number of passengers",
      timeExplain: "Time zone of the pick-up address",
      hours: "Hours",
      pickUp: "Pick-up Address",
      flightNumber: "Flight Number",
      returnAddress: "Return to the same address",
      dropOff: "Drop-off Address",
      enterLocation: "Enter your location",
      stop: "Stop",
      deleteStop: "Delete stop",
      stopTime: "Stop time in minutes",
      addStop: "Add stop",
    },
    contact: {
      contactDetails: "Contact details",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone number",
      name: "Name",
      password: "Password",
    },
    vehicles: {
      vehicleInfo: `We cannot guarantee this vehicle with such short lead time. Try contacting us at ${swanEmail} and maybe we can work something out.`,
      passCapacity: "Passengers capacity",
      luggageCapacity: "Luggage capacity",
      vehicleCount: "Vehicle count",
      vehicleSelection: "Vehicle Selection",
      vehicleNumber1: "The number of vehicles needed for",
      vehicleNumber2: "passengers:",
      shortNoticeCheckbox:
        "We cannot guarantee this vehicle with such short lead time. Do you still want to try to book?",
    },
    review: {
      tripDate: "Trip date",
      tripTime: "Trip time",
      route: "Route",
      noPass: "No. of passengers",
      vehicleSelected: "Vehicle selected",
      vehicleCount: "Vehicle count",
      duration: "Duration",
      min: "min",
      debugDetails: "Debug details",
      garageName: "Garage name",
      correctedTime: "Corrected time in minutes",
      fare: "Fare",
      mileageInflation: "Mileage inflation",
      routeMiles: "Route miles",
      routeMinutes: "Route minutes",
      timeMinutes: "Time in minutes",
      gratuity: "Gratuity",
      fuelSurcharge: "Fuel surcharge",
      totalPerVehicle: "Total per vehicle",
      total: "Total",
      flightNumber: "Flight number",
      units: "Units",
    },
    payment: {
      paymentDetails: "Payment details",
      cancelTitle: "Cancel/Rejected payment",
      cancelText:
        "Your payment was cancelled. You can fill the form again to make a reservation or contact us at",
      successTitle: "Thank you for your reservation.",
      successText:
        "You should receive your receipt in your email inbox. For additional information and requests, feel free to contact us at",
      saveTitle: "Thank you for your reservation.",
      saveText:
        "We got your request for reservation, and will contact you soon. For additional information and requests, feel free to contact us at",
    },
    terms: {
      termAndCond: "Terms and Conditions",
      acceptText: "By clicking on Make reservation and pay you accept",
      paragraph1:
        "Reservations - To confirm a reservation, a valid credit card is required to be added to the clients profile at the time of booking. All credit card details are stored securely via our online portal. Your credit card will be charged at the time of booking, not on the day of travel.",
      paragraph2:
        "We cannot accept any reservation that doesn't have the passengers cellphone and arriving flight details (airline and flight number). Flights run early or late, and to ensure that a vehicle is ready for you at all times, our dispatch team needs to be able to contact you.",
      paragraph3: "Cancellation policy: Sedan and SUV:",
      paragraph4:
        "If cancelled 24 hours prior to pickup time: 75% cancellation fee",
      paragraph5:
        "If cancelled 12 hours prior to pickup time: 100% cancellation fee",
      paragraph6:
        "Long Distance Trips (300 miles +). If cancelled 48 hours prior to pickup time 100% cancellation fee",
      paragraph7:
        "Hourly 'At Disposal' services: If cancelled 48 hours prior to pickup time 100% cancellation fee",
      paragraph8:
        "10% CSR Fee - non-refundable regardless when cancellation takes place",
      paragraph9: "Van and Sprinter:",
      paragraph10: "96 hours prior to pickup time: 75% cancellation fee",
      paragraph11: "48 hours prior to pickup time: 100%  cancellation fee",
      paragraph12:
        "25% CSR Fee - non-refundable regardless when cancellation takes place",
      paragraph13:
        "Cleaning Fees - A fee of $500.00 for each carpet or seat burn. Sanitation fee is $500.00.",
      paragraph14:
        "Alcohol & Drug Consumption - Alcohol is permitted in our vehicles. Drug use is prohibited by law. Any fines will be paid for by the customer. The driver has the right to terminate the run without refund (if there is blatant indiscretion on the part of the client(s)).",
      paragraph15:
        "Smoking - Smoking is not permitted in any of our vehicles. A $500.00 sanitation fee can be charged for non respect of our smoking policy.",
      paragraph16:
        "Overtime rates (wait time) - Overtime rate will apply after the first 15 minutes of pre-arranged time described on the run sheet. Overtime rate will apply after the first 30 minutes of the plane landing for the Domestic flights. Overtime rate will apply after the first 60 minutes of the plane landing for the International flights.",
      paragraph17:
        "Overloading - Vehicles cannot be loaded beyond legal seating capacity.",
      paragraph18:
        "Pets - We are pet friendly. All pets must be transported in an approved animal carrier/crate. Animals will not be permitted to ride in the cabin unsecured. Cleaning fees will apply for any mess caused by your pet.",
      paragraph19:
        "Wedding bookings cannot be cancelled within 14-days without forfeiting 100% of the booking. All weddings require a 50% non-refundable deposit at the time of reservation and full payment is due 14-days prior to the event.",
      paragraph20:
        "Company is not liable in the event of mechanical breakdown while on charter and will only be responsible for making up lost time at a mutually agreed date.",
      paragraph21:
        "Swan Limousine & Charters will not remain responsible for force majeure, including Acts of God, fire, flood, storms, war, civil unrest, military or police action, rebellion, banditry, riots, explosions, epidemics and any other event or circumstances beyond the reasonable control of Swan Limousine & Charters. It is Illegal to stand through the sunroof. We are not responsible for delays or termination during winter time caused by unsafe road conditions (ie. not salted, accidents, etc.). We are not responsible for articles left in the limousine.",
      paragraph22: "* Limousines & Buses Cancellation Policy *",
      paragraph23: "96 hours prior to pickup time: 75% cancellation fee",
      paragraph24: "48 hours prior to pickup time: 100% cancellation fee",
      paragraph25:
        "50% CSR Fee - non-refundable regardless when cancellation takes place.",
    },
  },
  fr: {
    swanData: {
      email: swanEmail,
      title: "SWAN Limo",
    },
    appData: {
      returnHome: "Return to Home Page",
      bookTrip: "Book a ride in a few ",
      clicks: "clicks",
      next: "Next",
      back: "Back",
      makeAndPay: "Make reservation and pay",
      tripInformation: "Trip information",
      yourTrip: "Your trip",
      hourly: "Hourly",
      transfer: "One Way",
      roundTrip: "Round Trip",
      select: "Select",
      review: "Review",
      tripDetails: "Trip Details",
    },
    steps: {
      trip: "Trips Details",
      vehicle: "Vehicle Information",
      review: "Review",
    },
    errors: {
      serverError: "Server error!",
      pickUpRequired: "Pick up is required",
      mustDigits: "Must be only digits",
      hoursRequired: "Hours is required",
      dropOffRequired: "Drop off is required",
      firstNameRequired: "First name is required",
      lastNameRequired: "Last name is required",
      emailValid: "Email must be in valid format",
      emailRequired: "Email is required",
      phoneValid: "A phone number is not valid",
      phoneRequired: "A phone number is required",
      stopRequired: "Stop is required",
      stopTimeRequired: "Stop time is required",
      dateTime: "Date & Time cannot be in past",
      passengersError: "Passengers number not valid",
      vehicleValidation: " At least one vehicle needs to be selected",
      maxFlightNumber: "Flight number must be less than 10 characters",
    },
    trips: {
      time: "Time",
      passengers: "Passengers",
      passengersPlaceholder: "Number of passengers",
      timeExplain: "Time zone of the pick-up address",
      hours: "Hours",
      pickUp: "Pick-up Address",
      flightNumber: "Flight Number",
      returnAddress: "Return to the same address",
      dropOff: "Drop-off Address",
      enterLocation: "Enter your location",
      stop: "Stop",
      deleteStop: "Delete stop",
      stopTime: "Stop time in minutes",
      addStop: "Add stop",
    },
    contact: {
      contactDetails: "Contact details",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone number",
      name: "Name",
    },
    vehicles: {
      vehicleInfo: "We cannot provide this vehicle during selected time period",
      passCapacity: "Passengers capacity",
      luggageCapacity: "Luggage capacity",
      vehicleCount: "Vehicle count",
      vehicleSelection: "Vehicle Selection",
      vehicleNumber1: "The number of vehicles needed for",
      vehicleNumber2: "passengers:",
      shortNoticeCheckbox:
        "We cannot guarantee this vehicle with such short lead time. Do you still want to try to book?",
    },
    review: {
      tripDate: "Trip date",
      tripTime: "Trip time",
      route: "Route",
      noPass: "No. passengers",
      vehicleSelected: "Vehicle selected",
      vehicleCount: "Vehicle count",
      duration: "Duration",
      min: "min",
      debugDetails: "Debug details",
      correctedTime: "Corrected time in minutes",
      fare: "Fare",
      mileageInflation: "Mileage inflation",
      routeMiles: "Route miles",
      routeMinutes: "Route minutes",
      timeMinutes: "Time in minutes",
      gratuity: "Gratuity",
      fuelSurcharge: "Fuel surcharge",
      totalPerVehicle: "Total per vehicle",
      total: "Total",
      flightNumber: "Flight number",
    },
    payment: {
      paymentDetails: "Payment details",
      cancelTitle: "Cancel/Rejected payment",
      cancelText:
        "Your payment was cancelled. You can fill the form again to make a reservation or contact us at",
      successTitle: "Thank you for your reservation.",
      successText:
        "You should receive your receipt in your email inbox. For additional information and requests, feel free to contact us at",
      saveTitle: "Thank you for your reservation.",
      saveText:
        "We got your request for reservation, and will contact you soon. For additional information and requests, feel free to contact us at",
    },
    terms: {
      termAndCond: "Terms and Conditions",
      acceptText: "By clicking on Make reservation and pay you accept",
      paragraph1:
        "Reservations - To confirm a reservation, a valid credit card is required to be added to the clients profile at the time of booking. All credit card details are stored securely via our online portal. Your credit card will be charged at the time of booking, not on the day of travel.",
      paragraph2:
        "We cannot accept any reservation that doesn't have the passengers cellphone and arriving flight details (airline and flight number). Flights run early or late, and to ensure that a vehicle is ready for you at all times, our dispatch team needs to be able to contact you.",
      paragraph3: "Cancellation policy: Sedan and SUV:",
      paragraph4:
        "If cancelled 24 hours prior to pickup time: 75% cancellation fee",
      paragraph5:
        "If cancelled 12 hours prior to pickup time: 100% cancellation fee",
      paragraph6:
        "Long Distance Trips (300 miles +). If cancelled 48 hours prior to pickup time 100% cancellation fee",
      paragraph7:
        "Hourly 'At Disposal' services: If cancelled 48 hours prior to pickup time 100% cancellation fee",
      paragraph8:
        "10% CSR Fee - non-refundable regardless when cancellation takes place",
      paragraph9: "Van and Sprinter:",
      paragraph10: "96 hours prior to pickup time: 75% cancellation fee",
      paragraph11: "48 hours prior to pickup time: 100%  cancellation fee",
      paragraph12:
        "25% CSR Fee - non-refundable regardless when cancellation takes place",
      paragraph13:
        "Cleaning Fees - A fee of $500.00 for each carpet or seat burn. Sanitation fee is $500.00.",
      paragraph14:
        "Alcohol & Drug Consumption - Alcohol is permitted in our vehicles. Drug use is prohibited by law. Any fines will be paid for by the customer. The driver has the right to terminate the run without refund (if there is blatant indiscretion on the part of the client(s)).",
      paragraph15:
        "Smoking - Smoking is not permitted in any of our vehicles. A $500.00 sanitation fee can be charged for non respect of our smoking policy.",
      paragraph16:
        "Overtime rates (wait time) - Overtime rate will apply after the first 15 minutes of pre-arranged time described on the run sheet. Overtime rate will apply after the first 30 minutes of the plane landing for the Domestic flights. Overtime rate will apply after the first 60 minutes of the plane landing for the International flights.",
      paragraph17:
        "Overloading - Vehicles cannot be loaded beyond legal seating capacity.",
      paragraph18:
        "Pets - We are pet friendly. All pets must be transported in an approved animal carrier/crate. Animals will not be permitted to ride in the cabin unsecured. Cleaning fees will apply for any mess caused by your pet.",
      paragraph19:
        "Wedding bookings cannot be cancelled within 14-days without forfeiting 100% of the booking. All weddings require a 50% non-refundable deposit at the time of reservation and full payment is due 14-days prior to the event.",
      paragraph20:
        "Company is not liable in the event of mechanical breakdown while on charter and will only be responsible for making up lost time at a mutually agreed date.",
      paragraph21:
        "Swan Limousine & Charters will not remain responsible for force majeure, including Acts of God, fire, flood, storms, war, civil unrest, military or police action, rebellion, banditry, riots, explosions, epidemics and any other event or circumstances beyond the reasonable control of Swan Limousine & Charters. It is Illegal to stand through the sunroof. We are not responsible for delays or termination during winter time caused by unsafe road conditions (ie. not salted, accidents, etc.). We are not responsible for articles left in the limousine.",
      paragraph22: "* Limousines & Buses Cancellation Policy *",
      paragraph23: "96 hours prior to pickup time: 75% cancellation fee",
      paragraph24: "48 hours prior to pickup time: 100% cancellation fee",
      paragraph25:
        "50% CSR Fee - non-refundable regardless when cancellation takes place.",
    },
  },
};

export default languages;


import API from "../api/api";
import TripQuotes from "../models/TripQuotes";


export class TripsService {

    async postTrip( tripData ) {
        const quoteData = await API.post(`trips/`, tripData) 
        return new TripQuotes(quoteData.data.trip, quoteData.data.quotes)
    }

}

export const tripsService = new TripsService()

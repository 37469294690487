import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../languages/LanguageContext";
import {
  c,
  t01,
  t02,
  t03,
  t04,
  t05,
  t06,
  t07,
  t08,
  t09,
} from "../../resources/images";
import SwipeableGallery from "../SwipeableGallery";

const images = [t01, t02, t03, t04, t05, t06, t07, t08, t09];

const Testimonials = (props) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <Grid container pt={8} spacing={2} justifyContent="center">
      {props.isDesktop ?
        <Grid
          container
          item
          md={4}
          lg={4}
          sx={{
            height: "75%",
            alignSelf: "center",
            width: {
              xs: "80%",
              sm: "60%",
            },
          }}
        >
          {images.map((image, id) => {
            return (
              <Grid
                item
                lg={4}
                md={6}
                sm={4}
                xs={6}
                key={id}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  component="img"
                  src={image.src}
                  alt={`car ${id}`}
                  sx={{ p: 0.5, height: "100px" }}
                />
              </Grid>
            );
          })}
        </Grid> 
        :
        <Grid
          container
          item
          md={4}
          lg={4}
          sx={{
            height: "75%",
            alignSelf: "center",
            width: {
              xs: "100%",
              sm: "60%",
            },
          }}
        >
          <SwipeableGallery images = {images}/>
        </Grid>

      }
      <Grid item md={8} lg={8}>
        <Typography
          variant="h3"
          pb={3}
          textAlign={"center"}
          sx={{
            paddingTop: {
              lg: 0,
              md: 0,
              sm: 3,
              xs: 3,
            },
          }}
        >
          {i18n.landingPage.testimonialsTitle}
        </Typography>
        <Divider
          sx={(t) => {
            return {
              bgcolor: t.palette.primary.main,
              marginLeft: "35%",
              marginBottom: 3,
            };
          }}
          width={"30%"}
        />
        <Grid container spacing={0.5}>
          <Grid item md={1} lg={1} xs={1} sm={1}>
            <Typography
              color={(t) => t.palette.primary.main}
              sx={{
                fontWeight: "400",
                fontSize: {
                  lg: "80px",
                  md: "80px",
                  sm: "50px",
                  xs: "50px",
                },
                transform: "rotate(-180deg)",
                textAlign: "center",
                fontFamily: "Times New Roman",
                marginTop: {
                  lg: -8,
                  md: -8,
                  sm: -5,
                  xs: -5,
                },
                marginBottom: "auto",
              }}
            >
              “
            </Typography>
          </Grid>
          <Grid item md={10} sm={10} xs={10}>
            <Typography textAlign={"justify"}>
              {i18n.landingPage.testimonialsText}
            </Typography>
          </Grid>
          <Grid item md={1} lg={1} xs={1} sm={1} sx={{ marginTop: "auto" }}>
            <Typography
              color={(t) => t.palette.primary.main}
              sx={{
                fontWeight: "400",
                fontSize: {
                  lg: "80px",
                  md: "80px",
                  sm: "50px",
                  xs: "50px",
                },
                fontFamily: "Times New Roman",
                marginBottom: {
                  lg: -8,
                  md: -8,
                  sm: -6,
                  xs: -6,
                },
                textAlign: "center",
              }}
            >
              “
            </Typography>
          </Grid>
          <Box display={"flex"} justifyContent={"center"} width="100%" p={3}>
            <img
              width={"100px"}
              height="100px"
              src={c.src}
              alt={"customer_image"}
            />
          </Box>
          <Typography variant="h5" textAlign={"center"} width={"100%"}>
            Zod
            <Box component="span" color={(t) => t.palette.primary.main}>
              {" "}
              G. -
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Testimonials;

import React from 'react'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';


const CountrySelect = ({ value, onChange, labels, initialCountry, props, ...rest }) => {

    return (
        <FormControl sx={{ m: 1,ml:0, minWidth: 120 }}>
            <Select
                {...rest}
                sx={{
                    '& .MuiInputBase-input': { paddingBottom: '0px', marginTop: 'auto' },
                    '.MuiSvgIcon-root ': {
                        position: 'absolute',
                        height: '100%',
                        paddingBottom: '10px'
                    }
                }}
                value={value ? value : initialCountry}
                onChange={event => onChange(event.target.value || undefined)}
                renderValue={(selected) => {
                    return <MenuItem >
                        <img
                            width={'30px'}
                            height={'20px'}
                            style={{ marginRight: '5px' }}
                            alt={value ? value : initialCountry}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value ? value : initialCountry}.svg`} />

                        +{getCountryCallingCode(value ? value : initialCountry)}
                    </MenuItem>
                }}
                variant='standard'
                error={
                    props.formik.touched["phone"] && Boolean(props.formik.errors["phone"])
                }
            >
                {getCountries().map((country) => (
                    <MenuItem
                        key={country}
                        value={country}
                    >
                        <img
                            width={'30px'}
                            height={'22px'}
                            style={{ marginRight: '5px' }}
                            alt={country}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`} />

                        {labels[country]} +{getCountryCallingCode(country)}
                    </MenuItem>
                ))}
            </Select>
            {props.formik.touched["phone"] && Boolean(props.formik.errors["phone"]) &&
                <FormHelperText> {props.formik.touched["phone"] && " "}</FormHelperText>
            }
        </FormControl>
    )
}

export default CountrySelect
import API from "../api/api";


export class PricingService {

    async getPricing() { 
        const data = await API.get(`pricing/`)
        return data
    }

}

export const pricingService = new PricingService()

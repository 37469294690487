import React from "react";
import { TextField } from "@mui/material";

const ContactTextField = (props, refs) => {

  return (
    <TextField
      inputRef={refs}
      name={props.name}
      label={props.label}
      type={props.type || 'text'}
      fullWidth
      variant="standard"
      error={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name])}
      helperText={props.formik.touched[props.name] && props.formik.errors[props.name]}
      onChange={(e) => {
        props.formik.setFieldValue(props.name, e.target.value);
        props.dispatch({
          type: "set_contact_data",
          payload: {
            ...props.state.contactData,
            [props.name]: e.target.value,
          },
        });
      }}
      defaultValue={props.formik.values[props.name]}
    />
  );
};

export default React.forwardRef(ContactTextField);

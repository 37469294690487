import React, { useState, useEffect } from "react";

import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Grid, Box, Button } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CardSlider = (props) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);
  const cardsPerStep = isDesktop ? 4 : 1;
  const maxSteps = Math.ceil(props.cards.length / cardsPerStep);
  const [cardsSteps, setCardsSteps] = useState(() => {
    const newArr = [];
    const oldArr = [...props.cards];
    while (oldArr.length) newArr.push(oldArr.splice(0, cardsPerStep));
    return newArr;
  });

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 800);
    setCardsSteps(() => {
      const newArr = [];
      const oldArr = [...props.cards];
      while (oldArr.length) newArr.push(oldArr.splice(0, cardsPerStep));
      return newArr;
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep == maxSteps - 1 ? 0 : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep == 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Button size="small" onClick={handleBack}>
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </Button>
      {cardsSteps.length > 0 && (
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={8000}
          autoplay={true}
          loop={true}
        >
          {cardsSteps.map((cards, index) => (
            <Grid container spacing={2} key={index}>
              {cards?.length !== 0 && cards?.map((card, id) => {
                return (<Grid item key={id} md={12 / cardsPerStep}>
                  {card}
                </Grid>)
              })}
            </Grid>
          ))}
        </AutoPlaySwipeableViews>
      )}
      <Button size="small" onClick={handleNext}>
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </Button>
    </Box>
  );
};

export default CardSlider;

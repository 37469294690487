import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import CustomCardTravelType from "../CustomCardTravelType";
import {
  businessPhoto,
  familyPhoto,
  leisurePhoto,
} from "../../resources/images";

const TravelTypes = () => {
  const { i18n } = useContext(LanguageContext);

  return (
    <Grid
      container
      padding={{
        lg: 5,
        md: 5,
        sm: 2,
        xs: 0.5,
      }}
      marginTop={8}
      display="flex"
      justifyContent="center"
      sx={(t) => ({
        background: `linear-gradient(-180deg, ${t.palette.primary.main}, transparent)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: {
          lg: "100% 300px",
        },
      })}
    >
      <CustomCardTravelType
        src={businessPhoto.src}
        titleText={i18n.landingPage.businessCardTitle}
        description={i18n.landingPage.businessCardDescription}
        color={(t) => t.palette.primary.main}
        learnMore={i18n.landingPage.learnMore}
      />
      <CustomCardTravelType
        src={familyPhoto.src}
        titleText={i18n.landingPage.familyCardTitle}
        description={i18n.landingPage.familyCardDescription}
        color={"white"}
        learnMore={i18n.landingPage.learnMore}
      />
      <CustomCardTravelType
        src={leisurePhoto.src}
        titleText={i18n.landingPage.leisureCardTitle}
        description={i18n.landingPage.leisureCardDescription}
        learnMore={i18n.landingPage.learnMore}
        color={"black"}
      />
    </Grid>
  );
};

export default TravelTypes;

import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { LanguageContext } from "../../languages/LanguageContext";
import {
  BalboaPark,
  CoronadoIsland,
  GaslampQuarter,
  MissionBay,
} from "../../resources/images";
import CustomCardPopularLocation from "../CustomCardPopularLocation";

const PopularLocations = () => {
  const { i18n } = useContext(LanguageContext);

  const locations = [
    {
      id: 0,
      title: "Gaslamp Quarter",
      description: "The historical Heart of San Diego",
      image: GaslampQuarter.src,
    },
    {
      id: 1,
      title: "Coronado Island",
      description: "With its typical and beautiful architecture",
      image: CoronadoIsland.src,
    },
    {
      id: 2,
      title: "Balboa Park",
      description: "Home of the unmatched San Diego Zoo",
      image: BalboaPark.src,
    },
    {
      id: 3,
      title: "Mission Bay",
      description: "Hosting Sea World and Belmont Park",
      image: MissionBay.src,
    },
  ];

  return (
    <>
      <Stack
        direction={{ lg: "row", md: "row", sm: "row", xs: "column" }}
        justifyContent="center"
        spacing={1}
        padding={7}
        mt={5}
        textAlign="center"
      >
        <Typography
          sx={{ fontSize: { lg: "4rem", md: "4rem", sm: "3rem", xs: "3rem" } }}
        >
          {i18n.landingPage.popularLocationsTitle1}
        </Typography>
        <Typography
          sx={(t) => ({
            color: `${t.palette.primary.main}`,
            fontSize: { lg: "4rem", md: "4rem", sm: "3rem", xs: "3rem" },
            // marginTop: {lg: 0, md: 0, sm: 0, xs: 5},
          })}
        >
          {i18n.landingPage.popularLocationsTitle2}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {locations.map((location) => (
          <Grid item key={location.id} md={3} lg={3} xs={12} sm={12}>
            <CustomCardPopularLocation
              title={location.title}
              description={location.description}
              image={location.image}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PopularLocations;

import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import Input from 'react-phone-number-input/input'
import TextFieldPhone from "./TextFieldPhone";
import PropTypes from 'prop-types'
import en from 'react-phone-number-input/locale/en'
import "./PhoneNumber.css"
import CountrySelect from "./CountrySelect"
import { Stack } from "@mui/material";

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired
}

const PhoneNumber = (props) => {
  const [userCountry, setUserCountry] = useState("US");
  const [userPhone, setUserPhone] = useState("");

  async function handleNavigator(pos) {
    const { latitude, longitude } = pos.coords;
    const userCountryCode = await lookupCountry({ latitude, longitude });
    setUserCountry(userCountryCode);
  }

  async function lookupCountry({ latitude, longitude }) {
    const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    const locationData = await fetch(URL).then((res) => res.json());
    const [{ address_components }] = locationData.results.filter(({ types }) =>
      types.includes("country")
    );
    const [{ short_name }] = address_components;
    return short_name;
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(handleNavigator, () =>
      console.warn("permission was rejected")
    );
  }, []);

  useEffect(() => {
    if (userPhone != '') {
      props.formik.setFieldValue("phone", userPhone);
      props.dispatch({
        type: "set_contact_data",
        payload: {
          ...props.state.contactData,
          phone: userPhone,
        },
      });
    }
  }, [userPhone])

  return (
    <Stack spacing={1} direction='row'>
      <CountrySelect
        labels={en}
        value={userCountry}
        onChange={setUserCountry}
        initialCountry={userCountry}
        props={props}
      />
      <Input
        sx={{
          paddingBottom: '8px !important', marginTop: 'auto !important'
        }}
        international
        name="phone"
        id="phoneNumber"
        country={userCountry}
        onChange={setUserPhone}
        value={props.formik.values['phone']}
        inputComponent={TextFieldPhone}
        error={
          props.formik.touched['phone'] &&
          Boolean(props.formik.errors['phone'])
        }
        helperText={
          props.formik.touched['phone'] && props.formik.errors['phone']
        }
      />
    </Stack>);
}

export default PhoneNumber;

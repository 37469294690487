import React, { useContext, useState } from "react";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { isVehicleSelected } from '../../../validation/vehiclesFormValidation';
import VehicleCard from './VehicleCard/VehicleCard';
import { LanguageContext } from '../../../languages/LanguageContext';
import { useEffect } from "react";
import { handleBack, onVehicleSelectionSubmit } from "../../AppContainer/utilities";


const VehicleForm = (props) => {
  const { i18n } = useContext(LanguageContext)
  const [vehicleCountAgreement, setVehicleCountAgreement] = useState(false)
  const [vehicleShortNoticeAgreement, setVehicleShortNoticeAgreement] = useState(false)
  const [selectedQuote, setSelectedQuote] = useState(null)

  const handleCountAgreementChange = (event) => {
    setVehicleCountAgreement(event.target.checked)
  }
  const handleShortNoticeAgreementChange = (event) => {
    setVehicleShortNoticeAgreement(event.target.checked)
  }

  useEffect(() => {
    const selected = props.state.tripQuotes.quotes.filter(
      (quote) => quote.vehicle_type?.id === props.state.selectedVehicle?.id
    )[0];
    setSelectedQuote(selected)
  }, [props.state.selectedVehicle])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {i18n.vehicles.vehicleSelection}
      </Typography>
      {props.state.tripQuotes !== null ? <>
        <Grid container spacing={3}>
          {props.state?.tripQuotes.quotes !== undefined && props.state.tripQuotes.quotes.map(quote =>
            <Grid key={quote.id} item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <VehicleCard quote={quote} state={props.state} dispatch={props.dispatch} />
            </Grid>)
          }
          <Grid item xs={12}>
            {props.state.selectedVehicle && (props.state.passengers > props.state.selectedVehicle?.passenger_capacity) &&
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  control={<Checkbox checked={vehicleCountAgreement} onChange={handleCountAgreementChange} />}
                  label={`${i18n.vehicles.vehicleNumber1} ${props.state.passengers} 
                  ${i18n.vehicles.vehicleNumber2} ${Math.ceil(props.state.passengers / props.state.selectedVehicle?.passenger_capacity)}`}
                />
              </Box>
            }
            {selectedQuote?.short_notice &&
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  control={<Checkbox checked={vehicleShortNoticeAgreement} onChange={handleShortNoticeAgreementChange} />}
                  label={i18n.vehicles.shortNoticeCheckbox}
                />
              </Box>
            }
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={() => { handleBack(props.dispatch, props.state, props.scrollToBookingSection, props.setShowLandingPage) }} sx={{ mt: 3, ml: 1 }}>
                {i18n.appData.back}
              </Button>

              <Button variant="contained"
                type="submit"
                sx={{ mt: 3, ml: 1 }}
                onClick={() => { onVehicleSelectionSubmit(props.dispatch, props.state) }}
                disabled={
                  props.state.selectedVehicle &&
                  (((props.state.passengers > props.state.selectedVehicle?.passenger_capacity) &&
                    !vehicleCountAgreement) ||
                    (selectedQuote?.short_notice &&
                      !vehicleShortNoticeAgreement))
                }
              >
                {i18n.appData.next}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {
          !isVehicleSelected(props.state.selectedVehicle) &&
          <Typography sx={{ display: 'flex', justifyContent: 'end', mt: '30px' }} gutterBottom color={(t) => t.palette.error.main}>
            {i18n.errors.vehicleValidation}
          </Typography>
        }
      </>
        :
        <Box
          sx={{ display: "flex", justifyContent: "center", mt: "40px" }}
        >
          <CircularProgress />
        </Box>
      }
    </React.Fragment >
  )
}

export default VehicleForm
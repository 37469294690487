import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";

const CustomCardPopularLocation = (props) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        borderTopRightRadius: "15px",
        borderTopLeftRadius: "15px",
        backgroundColor: "white",
      }}
    >
      <CardActionArea>
        <CardMedia component="img" height="200" image={props.image} />
        <CardContent sx={{ height: 110 }}>
          <Typography gutterBottom variant="h5" component="div" color={"black"}>
            {props.title}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 15 }} color={"black"}>
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CustomCardPopularLocation;

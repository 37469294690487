import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import AppContainer from "../components/AppContainer/AppContainer";
import Title from "../components/LandingPage/Title";
import Policy from "../components/LandingPage/Policy";
import Buttons from "../components/LandingPage/Buttons";
import TravelTypes from "../components/LandingPage/TravelTypes";
import Cars from "../components/LandingPage/Cars";
import Routes from "../components/LandingPage/Routes";
import ContactSponsors from "../components/LandingPage/ContactSponsors";
import CustomerReviews from "../components/LandingPage/CustomerReviews";
import PopularLocations from "../components/LandingPage/PopularLocations";
import Testimonials from "../components/LandingPage/Testimonials";

const MainPage = () => {
  const ref = useRef(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600)
  const [showLandingPage, setShowLandingPage] = useState(true)

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 600)
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  const scrollToBookingSection = () => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollIfPaymentHave = () => {
      if (
        window.location.href.includes("success-payment") ||
        window.location.href.includes("save-payment") ||
        window.location.href.includes("cancel-payment")
      ) {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: "auto",
        });
      }
    };
    scrollIfPaymentHave();
  });
 
  return (
    <Container component="main" maxWidth="lg">
      {showLandingPage && (<>
      <Title />
      <Buttons scrollToBookingSection={scrollToBookingSection} />
      {/* <Policy /> */}
      <TravelTypes /> </>)}
      <AppContainer ref={ref} scrollToBookingSection={scrollToBookingSection} staff={false} setShowLandingPage={setShowLandingPage} />
      {showLandingPage && ( <>
      <Cars />
      <CustomerReviews />
      <Testimonials isDesktop={isDesktop}/>
      <PopularLocations />
      <Routes />
      <ContactSponsors /> </>)}
    </Container>
  );
};

export default MainPage;

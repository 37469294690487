import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react'
import { useContext } from 'react';
import { LanguageContext } from '../../languages/LanguageContext';
import CustomCardReview from '../CustomCardReview';
import CardSlider from '../CardSlider';

const CustomerReviews = () => {
    const { i18n } = useContext(LanguageContext);

    const reviews = [
        {
            "author_name": "Sara Groenewold",
            "author_url": "https://www.google.com/maps/contrib/110237881533509660582/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a/AEdFTp550SeuA3ZPkZkU9ib2IVoA8mU2v2QYP6CXLOML=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "3 months ago",
            "text": "I booked with Swan for a Bachelorette party for myself and 5 girls. We set up a few different occasions in which we would use the driving service which included airport pickup, a night out, and a return to the airport. Everything went perfectly and we were able to spoil our bride! All the of the drivers were amazing, very professional,  recommended many great local restaurants and things to, and even surprised our bride with something fun and special to celebrate her for the weekend! If you are going to San Diego there is only one company I would even consider booking with and that is Swan! Thank you Swan and all of your drivers for adding to an unforgettable weekend!",
            "time": 1663894712,
            "translated": false
        },
        {
            "author_name": "Kerina Ludwig",
            "author_url": "https://www.google.com/maps/contrib/117726962856096338609/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AD5-WCl2fYHDkOkXdmsm2Ckzx0cSsgm7jByiCeGCAW76=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "3 months ago",
            "text": "I have worked with Swan Limousine car service for over five years now and have been so happy with their professionalism, drivers, cleanliness of their cars, and overall service. I have executives that are very picky and they have had nothing but great things to say about this company. I currently moved companies and use them with my new company and they are overjoyed with the service that they receive from Swan Limousine car service. If you’re looking for a car service, this is the company to go with! Do not look anywhere else!",
            "time": 1663874174,
            "translated": false
        },
        {
            "author_name": "Christine McMillan",
            "author_url": "https://www.google.com/maps/contrib/118362891070518717717/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AD5-WCmjfEknWdqIlVNmXKrhN9x2NIhHXbOROi8fv1mb=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "3 months ago",
            "text": "The amazing service totally made my day! Fernando was so friendly and sincere and kept me very up to date when he was coming to pretty much the middle of nowhere to pick me up. I made this booking very last minute and Swan was incredibly accommodating. Highly recommend!",
            "time": 1663523515,
            "translated": false
        },
        {
            "author_name": "Julie Evans",
            "author_url": "https://www.google.com/maps/contrib/108452487226706828758/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AD5-WClZiM52kH3P68b6mngxfk9MyvE9q1yM_iV75KAc_w=s128-c0x00000000-cc-rp-mo-ba3",
            "rating": 5,
            "relative_time_description": "3 months ago",
            "text": "I work for a limo company that sent customers to Swan as an affiliate and they took incredible care of our customers! 12/10 would use again! We have very high standards and they completely lived up to those standards! Thanks again!!",
            "time": 1663541040,
            "translated": false
        },
        {
            "author_name": "SanDiego Cliff",
            "author_url": "https://www.google.com/maps/contrib/117420487094324640575/reviews",
            "language": "en",
            "original_language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AD5-WCnJIQK7yb_MqEUbtoIYrcQfBb_jLULIjvpip9J1bA=s128-c0x00000000-cc-rp-mo-ba3",
            "rating": 5,
            "relative_time_description": "3 months ago",
            "text": "After getting stood up by a taxi service the last time I flew. This time I scheduled a trip with Swan and had a very comfortable ride. I would reccomend this car service to everyone.",
            "time": 1663542061,
            "translated": false
        }
    ]

    return (
        <>
            <Box
                sx={{
                    fontSize: { lg: "3rem", md: "3rem", sm: "2rem", xs: "2rem" },
                    textAlign: 'center',
                }}
            >
                {i18n.landingPage.reviewTitle1}
                <Box sx={(t) => ({
                    color:`${t.palette.primary.main}`,
                    fontSize: { lg: "3rem", md: "3rem", sm: "2rem", xs: "2rem" },
                    pb: 5
                })}>{i18n.landingPage.reviewTitle2}</Box>
            </Box>
            <CardSlider cards={reviews.map(review =>
                <CustomCardReview
                    titleText={review.author_name}
                    text={review.text}
                    date={dayjs.unix(review.time).format('MMMM DD, YYYY')}
                    stars={review.rating}
                    key={review.time}
                />)} />

        </>
    );
}

export default CustomerReviews
import React from "react";

import { Box, Grid } from "@mui/material";

const CustomSponsorImg = (props) => {
  return (
    <Grid item lg ={12/5} md={12/5} xs={12} sm={12} width={'100%'} alignSelf={"center"} textAlign={'center'}>
      <Box component={"img"} src={props.src} />
    </Grid>
  );
};

export default CustomSponsorImg;

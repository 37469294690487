import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import CustomCarShow from "../CustomCarShow";
import { premiumSedan, premiumSUV, sprinter, bus, minibus, motorcoache } from "../../resources/images";

const Cars = () => {
  const { i18n } = useContext(LanguageContext);

  return (
    <Grid
      container
      padding={5}
      paddingTop={2}
      marginTop={8}
      marginBottom={8}
      justifyContent="space-around"
      alignItems="center"
      sx={(t) => ({
        background: {
          lg: `linear-gradient(white 50%, ${t.palette.primary.main} 50%)`,
          md: `linear-gradient(-180deg, ${t.palette.primary.main}, transparent)`,
          sm: `linear-gradient(-180deg, ${t.palette.primary.main}, transparent)`,
          xs: `linear-gradient(-180deg, ${t.palette.primary.main}, transparent)`,
        },
        height: "100%",
      })}
    >
      <Grid
        item
        xs={12}
        sm={4}
        sx={{height: "100%",}}
      >
        <CustomCarShow
          src={premiumSedan.src}
          carTitle={i18n.landingPage.car1}
          carName={i18n.landingPage.car1description}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{height: "100%",}}
      >
        <CustomCarShow
          src={premiumSUV.src}
          carTitle={i18n.landingPage.car2}
          carName={i18n.landingPage.car2description}
          paddingTop={"-30px"}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{height: "100%",}}
      >
        <CustomCarShow
          src={sprinter.src}
          carTitle={i18n.landingPage.car3}
          // carName={i18n.landingPage.car3description}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{height: "100%",}}
      >
        <CustomCarShow
          src={minibus.src}
          carTitle={i18n.landingPage.car4}
          // carName={i18n.landingPage.car4description}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{height: "100%",}}
      >
        <CustomCarShow
          src={bus.src}
          carTitle={i18n.landingPage.car5}
          // carName={i18n.landingPage.car5description}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{height: "100%",}}
      >
        <CustomCarShow
          src={motorcoache.src}
          carTitle={i18n.landingPage.car6}
          // carName={i18n.landingPage.car6description}
        />
      </Grid>
    </Grid>
  );
};

export default Cars;

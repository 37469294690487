import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import { LanguageContext } from "../languages/LanguageContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { staffService } from "../services/staffService";
import { logo } from "../resources/images";

const LoginPage = () => {
  const { i18n } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const initialValues = {
    email: "",
    password: "",
  };

  const onSignIn = async (values) => {
    try {
      setError("");
      const user = {
        email: values.email,
        password: values.password,
      };

      let res = await staffService.loginStaff(user);

      if (res.status === 200) {
        const tok = JSON.stringify(res.data);
        const parsedData = JSON.parse(tok);

        localStorage.setItem("token", parsedData.access);
        localStorage.setItem("refreshToken", parsedData.refresh);

        navigate("/staff-quote");
        navigate(0);
      }
    } catch (error) {
      setError(i18n.errors.checkYou);
    }
  };

  const loginSchema = yup.object({
    email: yup
      .string()
      .email(i18n.errors.emailValid)
      .required(i18n.errors.emailRequired),
    password: yup.string().required(i18n.errors.passwordRequired),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: onSignIn,
  });

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", alignItems: "center", height: "100vh" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: '80px', height: '80px' }}>
          <Box component={"img"} src={logo.src} sx={{ width: '50px' }} />
        </Avatar>
        <Typography variant="h4" textAlign={"center"}>
          {i18n.staff.staffLogin}
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            autoFocus
            id="email"
            name="email"
            label={i18n.contact.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label={i18n.contact.password}
            name="password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error !== "" && (
            <Typography variant="body" color={(t) => t.palette.error.main}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {i18n.staff.login}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;

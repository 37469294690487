import React, { useContext } from "react";

import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";

import { LanguageContext } from "../../languages/LanguageContext";
import SuccessReservation from "../SuccessReservation";
import { Box } from "@mui/material";
import { getStepContent } from "./utilities";

const MobileContainer = (props) => {
  const { i18n } = useContext(LanguageContext);
  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4, p: 0, pt: 4 }}>
      <Typography component="h1" variant="h4" align="center">
        {i18n.appData.bookTrip.toUpperCase()} <Box component="span" color={(t) => t.palette.primary.main}>{i18n.appData.clicks.toUpperCase()}</Box>
      </Typography>
      <Stepper
        activeStep={props.state.activeStep}
        sx={{ pt: 3, pb: 5 }}
        orientation="vertical"
      >
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            {props.state.activeStep < props.steps.length && (
              <StepContent>
                <React.Fragment>
                  {getStepContent({
                    ...props,
                    step: props.state.activeStep,
                  })}
                </React.Fragment>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
      {props.state.activeStep === props.steps.length && <SuccessReservation />}
    </Container>
  );
};

export default MobileContainer;

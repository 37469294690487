import React, { useContext } from "react";
import { TextField } from "@mui/material";
import { LanguageContext } from "../../languages/LanguageContext";

const TextFieldPhone = (props, refs) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <TextField
      inputRef={refs}
      label={i18n.contact.phone}
      fullWidth
      variant="standard"
      {...props}
    />
  );
};

export default React.forwardRef(TextFieldPhone);

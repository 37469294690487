import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import CustomButton from "../CustomButton";

const Buttons = (props) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <Grid
      container
      paddingTop={10}
      paddingBottom={5}
      paddingX={'15%'}
      spacing={0}
    >
      <Grid item md={6} xs={12} sx={{display: 'flex', justifyContent:"center"}}>
        <CustomButton text={i18n.landingPage.bookNow} onClick={props.scrollToBookingSection} />
      </Grid>
      <Grid item md={6} xs={12} sx={{display: 'flex', justifyContent:"center"}}>
        <CustomButton text={i18n.landingPage.callUs} href={`tel:${i18n.swanData.phoneCall}`} />
      </Grid>
    </Grid>
  );
};

export default Buttons;

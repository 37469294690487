import React, { useContext } from "react";
import { List, ListItem, Typography } from "@mui/material";
import { LanguageContext } from "../languages/LanguageContext";

const TermsAndCondition = () => {
  const { i18n } = useContext(LanguageContext);
  return (
    <>
      <List
        sx={{
          listStyleType: "decimal",
          pl: 4,
          pr: 0,
          m: 2,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}
      >
        <ListItem>{i18n.terms.paragraph1}</ListItem>
        <Typography sx={{ padding: 0 }}>{i18n.terms.paragraph2}</Typography>
        <ListItem sx={{ whiteSpace: "pre-line" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {i18n.terms.paragraph3}
          </Typography>
          <Typography>{i18n.terms.paragraph4}</Typography>
          <Typography>{i18n.terms.paragraph5}</Typography>
          <Typography>{i18n.terms.paragraph6}</Typography>
          <Typography>{i18n.terms.paragraph7}</Typography>
          <Typography>{i18n.terms.paragraph8}</Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {i18n.terms.paragraph9}
          </Typography>
          <Typography>{i18n.terms.paragraph10}</Typography>
          <Typography>{i18n.terms.paragraph11}</Typography>
          <Typography>{i18n.terms.paragraph12}</Typography>
        </ListItem>
        <ListItem>{i18n.terms.paragraph13}</ListItem>
        <ListItem>{i18n.terms.paragraph14}</ListItem>
        <ListItem>{i18n.terms.paragraph15}</ListItem>
        <ListItem>{i18n.terms.paragraph16}</ListItem>
        <ListItem>{i18n.terms.paragraph17}</ListItem>
        <ListItem>{i18n.terms.paragraph18}</ListItem>
        <ListItem>{i18n.terms.paragraph19}</ListItem>
        <ListItem>{i18n.terms.paragraph20}</ListItem>
        <ListItem sx={{ whiteSpace: "pre-line" }}>
          <Typography>{i18n.terms.paragraph21}</Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {i18n.terms.paragraph22}
          </Typography>
          <Typography>{i18n.terms.paragraph23}</Typography>
          <Typography>{i18n.terms.paragraph24}</Typography>
          <Typography>{i18n.terms.paragraph25}</Typography>
        </ListItem>
      </List>
    </>
  );
};

export default TermsAndCondition;

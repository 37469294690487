import React, { useContext } from "react";

import { Box, Grid, Link, Typography } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import { logo } from "../../resources/images";

const Title = () => {
  const { i18n } = useContext(LanguageContext);

  return (
    <Grid container paddingTop={10}>
      <Grid
        item
        lg={2}
        md={3}
        sm={12}
        xs={12}
        alignSelf="center"
        textAlign="center"
      >
        <Link href="/">
          <Box component={"img"} src={logo.src} />
        </Link>
      </Grid>
      <Grid item lg={10} md={9} sm={12} xs={12} alignSelf="center">
        <Typography
          variant="h1"
          sx={{ fontSize: { lg: "6rem", md: "6rem", sm: "3rem", xs: "3rem" } }}
          textAlign={"center"}
          textTransform="uppercase"
        >
          {i18n.landingPage.mainTitle}
        </Typography>
        <Typography variant="h4" textAlign={"center"} color="primary">
          {i18n.landingPage.textTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Title;

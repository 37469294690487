import React from 'react'
import { Box } from '@mui/material'
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api'
import { useMemo } from 'react'
import { useEffect } from 'react'

const RouteMap = (props) => {
    useEffect(() => {

        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };

                let center = {
                    lat: props.state.pickUp?.geometry?.location.lat(),
                    lng: props.state.pickUp?.geometry?.location.lng(),
                }
                if (!center?.lat) {
                    props.dispatch({ type: "set_map_center", payload: pos });
                } else {
                    props.dispatch({ type: "set_map_center", payload: center });
                }
            }
        );

    }, [props.state.pickUp])


    const map = useMemo(() => {

        let bounds = null
        if (props.dropOffMarker?.lng) {
            bounds = new window.google.maps.LatLngBounds()
            bounds.extend(props.state.mapCenter)
            bounds.extend(props.dropOffMarker)
        }
        return (<GoogleMap
            center={props.state.mapCenter}
            zoom={15}
            ref={m => m && bounds && m?.state?.map && m.state.map.fitBounds(bounds)}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
        >
            {!props.directionsResponse && <Marker position={props.state.mapCenter} />}
            {props.state.mode === 'HOURLY' &&
                !props.state.isDropOffSame &&
                props.dropOffMarker?.lng &&
                <Marker position={props.dropOffMarker} />}
            {props.directionsResponse && (
                <DirectionsRenderer directions={props.directionsResponse} />
            )}
        </GoogleMap>)
    }, [props.directionsResponse, props.state.mapCenter, props.dropOffMarker])

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            {map}
        </Box>
    )
}

export default RouteMap
import React, { useContext } from "react";

import { Grid, Stack, Typography } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import CustomRouteCard from "../CustomRouteCard";

const Routes = () => {
  const { i18n } = useContext(LanguageContext);

  let routeNumbers = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <React.Fragment>
      <Stack direction={"row"} justifyContent="center" spacing={1} padding={7}>
        <Typography
          sx={{ fontSize: { lg: "4rem", md: "4rem", sm: "3rem", xs: "3rem" } }}
        >
          {i18n.landingPage.topRoutes1}
        </Typography>
        <Typography
          sx={(t) => ({
            color: `${t.palette.primary.main}`,
            fontSize: { lg: "4rem", md: "4rem", sm: "3rem", xs: "3rem" },
          })}
        >
          {i18n.landingPage.topRoutes2}
        </Typography>
      </Stack>
      <Grid container>
        {routeNumbers.map((e) => {
          return (
            <Grid item key={e} lg={3} md={4} sm={6} xs={6}>
              <CustomRouteCard
                route={i18n.landingPage.routes[`route${e}`]}
                price={i18n.landingPage.routes[`price${e}`]}
              />
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default Routes;

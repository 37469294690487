import React, { useContext } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import LuggageIcon from "@mui/icons-material/Luggage";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Tooltip from "@mui/material/Tooltip";
import { LanguageContext } from "../../../../languages/LanguageContext";
import InfoIcon from '@mui/icons-material/Info';

const VehicleCard = (props) => {
  const { i18n } = useContext(LanguageContext);
  const vehicle = props.quote?.vehicle_type;

  const handleSelectVehicle = () => {
    props.dispatch({ type: "set_selected_vehicle", payload: vehicle });
  };

  return (
    <Tooltip
      enterTouchDelay={0}
      title={props.quote.short_notice ? i18n.vehicles.vehicleInfo : ""}
    >
      {vehicle !== null && vehicle !== undefined && (
        <Card
          sx={(t) => ({
            marginTop: 4,
            width: "100%",
            maxWidth: 345,
            border:
              props.state.selectedVehicle?.id === vehicle.id
                ? `1px solid ${t.palette.primary.main}`
                : "",
            textAlign: "center",
            borderRadius: "20px 20px",
            // boxShadow:
            //   props.state.selectedVehicle?.id === vehicle.id
            //     ? `0 0 25px 1px ${t.palette.primary.main}`
            //     : "0 0 0 #fff",
            transition: "transform 0.15s ease-in-out",
            "&:hover": {
              boxShadow:
                `0 0 25px 1px ${t.palette.primary.main}`,
              transform: "scale3d(1.05, 1.05, 1)"
            }
          })}
          onClick={() => {
            handleSelectVehicle();
          }}
        >
          <CardHeader
            title={vehicle?.name}
            sx={{ opacity: props.quote.short_notice ? "0.3" : "1" }}
          />
          {props.quote.short_notice && !props.state.isDesktop && (
            <CardContent
              sx={{ opacity: props.quote.short_notice ? "0.3" : "1" }}
            >
              <Typography color={(t) => t.palette.error.main}>
                {i18n.vehicles.vehicleInfo}
              </Typography>
            </CardContent>
          )}
          <CardMedia
            sx={{
              p: 2,
              objectFit: "contain",
              opacity: props.quote.short_notice ? "0.3" : "1",
            }}
            component="img"
            height="250px"
            width="250px"
            image={`${vehicle?.image}`}
            alt="car image"
          />
          <CardContent sx={{ opacity: props.quote.short_notice ? "0.3" : "1" }}>
            <Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"}>
                  <GroupIcon />
                  <Typography color={(t) => t.palette.grey[500]} sx={{ ml: 2 }}>
                    {" "}
                    {i18n.vehicles.passCapacity}{" "}
                  </Typography>
                </Box>
                <Typography> {vehicle?.passenger_capacity} </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"}>
                  <LuggageIcon />
                  <Typography color={(t) => t.palette.grey[500]} sx={{ ml: 2 }}>
                    {" "}
                    {i18n.vehicles.luggageCapacity}{" "}
                  </Typography>
                </Box>
                <Typography> {vehicle?.luggage_capacity} </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"}>
                  <DirectionsCarIcon />
                  <Typography color={(t) => t.palette.grey[500]} sx={{ ml: 2 }}>
                    {" "}
                    {i18n.vehicles.vehicleCount}{" "}
                  </Typography>
                </Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  {Math.ceil(
                    props.state.passengers / vehicle?.passenger_capacity
                  )}{" "}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }} color={(t) => t.palette.primary.main}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ mt: 2 }}
              >
                {props.quote?.total}
              </Typography>
              <Box>
                <Tooltip title={
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary={i18n.review.fare + ": " + props.quote?.fare } />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={i18n.review.gratuity + ": " + props.quote?.gratuity} />
                    </ListItem>
                    { Number(props.quote?.fuel_surcharge.substring(1))>0 &&
                    <ListItem>
                      <ListItemText primary={i18n.review.fuelSurcharge + ": " + props.quote?.fuel_surcharge} />
                    </ListItem>}
                    <ListItem>
                      <ListItemText primary={i18n.review.totalPerVehicle + ": " + props.quote?.total_per_vehicle} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={i18n.review.total + ": " + props.quote?.total} />
                    </ListItem>
                  </List>} placement="right" >
                  <InfoIcon sx={{ mt: 2.5, ml: 2 }} />
                </Tooltip>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Tooltip>
  );
};

export default VehicleCard;

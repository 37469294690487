export const logo = {
    src: require("./images/logo.png")
}

export const businessPhoto = {
    src: require("./images/BusinessPhoto.png")
}

export const familyPhoto = {
    src: require("./images/FamilyPhoto.png")
}

export const leisurePhoto = {
    src: require("./images/LeisurePhoto.png")
}

export const premiumSUV = {
    src: require("./images/PremiumSUV.png")
}

export const premiumSedan = {
    src: require("./images/PremiumSedan.png")
}

export const sprinter = {
    src: require("./images/sprinter.png")
}

export const bus = {
    src: require("./images/bus.png")
}

export const minibus = {
    src: require("./images/minibus.png")
}

export const motorcoache = {
    src: require("./images/motorcoache.png")
}

export const GoogleLogo = {
    src: require("./images/GoogleLogo.png")
}

export const BBBlogo = {
    src: require("./images/BBBLogo.png")
}

export const GCLAlogo = {
    src: require("./images/GCLALogo.png")
}

export const BalboaPark = {
    src: require("./images/BalboaPark.png")
}

export const CoronadoIsland = {
    src: require("./images/CoronadoIsland.png")
}

export const GaslampQuarter = {
    src: require("./images/GaslampQuarter.png")
}

export const MissionBay = {
    src: require("./images/MissionBay.png")
}

export const NLALogo = {
    src: require("./images/NLALogo.png")
}

export const YelpLogo = {
    src: require("./images/YelpLogo.png")
}

export const t01 = {
    src: require("./images/t01.jpg")
}

export const t02 = {
    src: require("./images/t02.jpg")
}

export const t03 = {
    src: require("./images/t03.jpg")
}

export const t04 = {
    src: require("./images/t04.jpg")
}

export const t05 = {
    src: require("./images/t05.jpg")
}

export const t06 = {
    src: require("./images/t06.jpg")
}

export const t07 = {
    src: require("./images/t07.jpg")
}

export const t08 = {
    src: require("./images/t08.jpeg")
}

export const t09 = {
    src: require("./images/t09.jpeg")
}

export const c = {
    src: require("./images/c.png")
}

import API from "../api/api";
import API_AUTH from "../api/api_auth";
import TripQuotes from "../models/TripQuotes";

export class StaffService {

    async loginStaff( payload ) {
        const data = await API.post('/auth/jwt/create/', payload) 
        return data
    }

    async postTrip( tripData ) {
        const quoteData = await API_AUTH.post(`/staff/quotes/`, tripData) 
        return new TripQuotes(quoteData.data.trip, quoteData.data.quotes)
    }

}

export const staffService = new StaffService()
